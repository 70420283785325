import Head from "next/head";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const DashboardLayout = dynamic(() => import("../app/layouts/Dashboard"));
const NotFound = dynamic(() => import("../app/pages/NotFound"));

const NotFound404 = () => {
  const router = useRouter();
  const [mypath, setMypath] = useState("");
  const [whichOne, setWhichOne] = useState("");
  const [profile, setProfile] = useState("");
  const [color, setColor] = useState("primary");

  const getProfile = async (params: any) => {
    const w = params == "b" ? "buyer" : "facility";
    const c = params == "b" ? "primary" : "purple";

    const cek =
      localStorage.getItem(params === "b" ? "b/profile" : "f/profile") || "";
    if (cek) {
      setProfile(JSON.parse(cek));
    }
    setWhichOne(w);
    setColor(c);
  };

  useEffect(() => {
    const myarr: any = router.asPath.split("/");
    setMypath(myarr[1]);
  }, [router.asPath]);

  useEffect(() => {
    if (mypath == "b" || mypath == "f") {
      getProfile(mypath);
    } else {
      setProfile("");
    }
  }, [mypath]);

  return (
    <>
      {profile != "" ? (
        <DashboardLayout colors={`${color}`} sidebarFor={`${whichOne}`}>
          <Head>
            <title>404 - Not Found </title>
            <meta name="description" content="Not found page" />
          </Head>
          <NotFound
            redirect={`/${mypath}`}
            color={mypath === "b" ? "primary" : "success"}
          />
        </DashboardLayout>
      ) : (
        <div className="container mx-auto px-6 py-10 h-auto md:h-screen flex flex-col">
          <div className="mx-auto flex flex-col justify-center items-center text-center h-full align-middle">
            <Head>
              <title>404 - Not Found</title>
              <meta name="description" content="Not found page" />
            </Head>
            <NotFound redirect="/" />
          </div>
        </div>
      )}
    </>
  );
};

export default NotFound404;
